import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

const styles = cva('grid grid-cols-1 gap-x-gutter', {
  variants: {
    extraVerticalSpacing: {
      true: 'gap-y-xl',
      false: 'gap-y-gutter',
    },
    numberOfColumns: {
      1: '',
      2: 'sm:grid-cols-2',
      3: 'sm:grid-cols-2 lg:grid-cols-3',
      4: 'sm:grid-cols-2 lg:grid-cols-4',
    },
    withContainer: {
      true: 'container',
      false: '',
    },
  },
});

export interface CardGridProps {
  className?: string;
  extraVerticalSpacing?: boolean;
  numberOfColumns?: 1 | 2 | 3 | 4;
  withContainer?: boolean;
}

export default function CardGrid({
  children,
  className,
  extraVerticalSpacing = false,
  numberOfColumns = 4,
  withContainer = false,
}: PropsWithChildren<CardGridProps>) {
  const componentName = 'card-grid';
  return (
    <div
      className={twMerge(
        styles({extraVerticalSpacing, numberOfColumns, withContainer}),
        className,
      )}
      data-component-name={componentName}
    >
      {children}
    </div>
  );
}
